main > .container-fluid {
    padding: 60px 80px;
}

.border-left-primary {
    border-left: 0.25rem solid #4e73df !important;
}

.border-left-info {
    border-left: 0.25rem solid #36b9cc !important;
}

.prediction-table .btn {
    width: 120px;
}

.prediction-new-button {
    margin-top: 20px;
    max-width: 160px;
}

.form-signin {
    width: 100%;
    max-width: 450px;
    padding: 15px;
    margin: auto;
    text-align: center;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-signin img {
    max-width: 100%;
    max-height: 100%;
}

.prediction-img {
    max-height: 80px;
}

.prediction-with-media .prediction-text {
    margin-top: 10px;
    margin-bottom: 0;
}